import React, { FC } from 'react';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';
import { isNil } from 'ramda';

interface IProps {
  label: string;
  value?: number;
  valueClassName?: string;
}

const ExchangeHeadCard: FC<IProps> = observer(({ label, value, valueClassName }) => {
  if (isNil(value) || isNaN(value)) return <div className={styles.placeholder} />;

  return (
    <div className={styles.card}>
      <label>{label}</label>
      <div className={valueClassName}>{value}</div>
    </div>
  );
});

export { ExchangeHeadCard };
