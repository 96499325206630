import React, { FC, useCallback, useEffect, useState } from 'react';
import styles from '~/config/pages/exchange/view/ExchangeItemViewer/styles.module.scss';
import { IExchangeReport, IExchangeReportItem } from '~/config/pages/exchange/types';
import { ExchangeItemRow } from '~/config/pages/exchange/view/ExchangeItemRow';
import { TablePagination } from '@material-ui/core';
import { Placeholder } from '~/config/components/Placeholder';
import { ExchangeEntity } from '~/config/pages/exchange/components/ExchangeEntity';
import { observer } from 'mobx-react';
import { flow } from 'mobx';
import {
  getPersistedReportsPerPage,
  setPersistedReportsPerPage,
} from '~/config/pages/exchange/utils';
import { getExchangeReports } from '~/config/pages/exchange/api';
import { CancellablePromise } from 'mobx/lib/api/flow';

interface IProps {
  entity: ExchangeEntity;
  id: string;
}

const TdPlaceholder = () => (
  <td>
    <Placeholder />
  </td>
);

const ExchangeItemViewerReports: FC<IProps> = observer(({ entity, id }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<IExchangeReport>();
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState(getPersistedReportsPerPage());
  const [total, setTotal] = useState(0);

  const onChangePage = useCallback((val: number) => setPage(val), [setPage]);
  const onChangeRows = useCallback((val: number) => setRows(val), [setRows]);

  useEffect(() => {
    onChangePage(0);
    setPersistedReportsPerPage(rows);
  }, [onChangePage, rows]);

  useEffect(() => {
    const callee: CancellablePromise<any> = flow(function* () {
      const withToken = entity.parent?.auth?.withToken;

      if (!withToken) return;

      setIsLoading(true);
      const result = yield withToken(getExchangeReports, {
        url: entity.api?.get.url || '',
        id,
        limit: rows,
        offset: page * rows,
      });
      setIsLoading(false);

      if (!result.data?.data) return;

      setData(result.data.data);
      setTotal(parseInt(result.headers['x-total-count'] || 0, 10));
    })();

    return () => callee.cancel();
  }, [page, rows, id, entity.parent, entity.api]);

  const changePage = useCallback((_, page) => onChangePage(page), [onChangePage]);
  const changeRowsPerPage = useCallback((event) => onChangeRows(event.target.value), [
    onChangeRows,
  ]);

  return (
    <table className={styles.table}>
      <colgroup>
        <col width="10%" />
        <col width="5%" />
        <col width="10%" />
        <col width="10%" />
        <col width="10%" />
        <col width="10%" />
        <col width="10%" />
        <col width="10%" />
      </colgroup>

      <thead>
        <tr>
          <th>Date</th>
          <th>Side</th>
          <th>Amount</th>
          <th>Buy</th>
          <th>Sell</th>
          <th>
            Commission
            <br />
            {data?.commissionAsset}
          </th>
          <th>
            Profit
            <br />
            {data?.profitAsset}
          </th>
          <th>
            Tot.Profit
            <br />
            {data?.profitAsset}
          </th>
          <th>
            Balance {data?.baseAsset} Currency
            <br />
            (Free / Total)
          </th>
          <th>
            Balance {data?.quoteAsset} Currency
            <br />
            (Free / Total)
          </th>
        </tr>
      </thead>

      <tbody>
        {!!data?.itemList &&
          !isLoading &&
          (data.itemList as IExchangeReportItem[]).map((item) => (
            <ExchangeItemRow item={item} key={item.id} />
          ))}

        {isLoading &&
          [...new Array(rows)].map((_, i) => (
            <tr key={i}>
              {[...new Array(8)].map((_, j) => (
                <TdPlaceholder key={j} />
              ))}
            </tr>
          ))}

        <tr>
          <TablePagination
            page={page}
            rowsPerPage={rows}
            count={total || 0}
            onChangePage={changePage}
            onChangeRowsPerPage={changeRowsPerPage}
          />
        </tr>
      </tbody>
    </table>
  );
});

export { ExchangeItemViewerReports };
