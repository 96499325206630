import {
  Entity,
  IEntityCreateFunctionProps,
  IEntityCreateFunctionResult,
  IEntityFetchFunction,
  IEntityGetFunction,
  IEntityGetFunctionProps,
} from 'icerockdev-admin-toolkit';
import { IExchangePriceSymbol } from './types';
import qs from 'qs';
import { has, pick } from 'ramda';
import { axios } from '~/utils/axios';

export const fetchExchangeItems: IEntityFetchFunction = async ({
  url,
  token,
  page = 0,
  count,
  sortDir,
  sortBy,
  filter,
}) => {
  const offset = page && count ? page * count : 0;
  const limit = count ? (page + 1) * count : 25;
  const orderBy = sortDir.toUpperCase();
  const filters: Record<string, string> =
    filter?.reduce((obj, item) => ({ ...obj, [item.name]: item.value }), {}) || {};

  const hash = window.location.hash.slice(1, window.location.hash.length);
  const queryParams = qs.parse(hash);

  // fetching orders
  const response = await axios.get(url, {
    params: {
      offset,
      limit,
      ...(sortBy ? { sortBy, orderBy } : {}),
      ...filters,
      isActive: queryParams._isActive ?? 'true',
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
    headers: { authorization: token },
  });

  const list = response.data.data;

  return {
    data: {
      list,
      totalCount: parseInt(response.headers['x-total-count'] || 0, 10),
    },
    filterData: (list && list[0]) || {}, // we use it for salesUserList, teamLeadUserList and responsibleUserList
    error: '',
  };
};

const getExchangeItemData = async ({ url, id, token }: IEntityGetFunctionProps) =>
  axios.get(`${url}/${id}`, {
    headers: {
      authorization: token,
    },
  });

export const getExchangeReports = async ({
  url,
  id,
  token,
  limit,
  offset,
}: IEntityGetFunctionProps & { limit: number; offset: number }) =>
  axios.get(`${url}/${id}/report`, {
    headers: {
      authorization: token,
    },
    params: {
      limit,
      offset,
    },
  });

export const getExchangeActiveOrders = async ({
  url,
  id,
  token,
  limit,
  offset,
}: IEntityGetFunctionProps & { limit: number; offset: number }) =>
  axios.get(`${url}/${id}/order/active`, {
    headers: {
      authorization: token,
    },
    params: {
      limit,
      offset,
    },
  });
export const getExchangeHistory = async ({
  url,
  id,
  token,
  limit,
  offset,
}: IEntityGetFunctionProps & { limit: number; offset: number }) =>
  axios.get(`${url}/${id}/history`, {
    headers: {
      authorization: token,
    },
    params: {
      limit,
      offset,
    },
  });

export const getExchangeItem: IEntityGetFunction = async ({ url, id, token }) => {
  const result = await getExchangeItemData({ url, id, token });

  return {
    data: result.data.data,
    error: '',
  };
};

export const fetchExchangeAlgorithmStock = (url: string) => async (
  entity: Entity
): Promise<Record<string, string>> => {
  const withToken = entity.parent?.auth?.withToken;

  if (!withToken) return {};

  const response = await withToken(
    ({ token }: { token: string }) =>
      axios.get(url, {
        params: {
          offset: 0,
          limit: 9999,
        },
        headers: { authorization: token },
      }),
    {}
  );

  if (!response?.data?.data || !response.data.data.length) return {};

  return response.data.data
    .sort((a: any, b: any) => a?.name?.localeCompare(b?.name))
    .reduce(
      (obj: Record<string, string>, item: { name: string; id: number }) => ({
        ...obj,
        [item.id]: item.name,
      }),
      {}
    );
};

export const fetchExchangeCustomers = async ({
  url,
  exchangeId,
  token,
}: {
  url: string;
  exchangeId: string;
  token: string;
}): Promise<Record<string, string>> => {
  const response = await axios.get(`${url}/${exchangeId}`, {
    params: {
      offset: 0,
      limit: 9999,
    },
    headers: { authorization: token },
  });

  if (!response?.data?.data || !response.data.data.length) return {};

  return response.data.data
    .map((it: any) => ({ ...it, name: [it.lastName, it.firstName, it.middleName].join(' ') }))
    .sort((first: any, second: any) => first?.name?.localeCompare(second?.name))
    .reduce(
      (obj: Record<string, string>, item: { firstName: string; lastName: string; id: number }) => ({
        ...obj,
        [item.id]: [item.firstName, item.lastName].join(' '),
      }),
      {}
    );
};
export const fetchExchangeSymbols = async ({
  url,
  exchangeId,
  token,
}: {
  url: string;
  exchangeId: string;
  token: string;
}): Promise<Record<string, string>> => {
  const response = await axios.get(`${url}/${exchangeId}`, {
    params: {
      offset: 0,
      limit: 9999,
    },
    headers: { authorization: token },
  });

  if (!response?.data?.data || !response.data.data.length) return {};

  const options = response.data.data
    .sort((a: any, b: any) => a?.symbol?.localeCompare(b?.symbol))
    .reduce((obj: Record<string, string>, item: { symbol: string }) => {
      return { ...obj, [item.symbol]: item.symbol };
    }, {});

  const precision = response.data.data.reduce(
    (acc: any, item: any) => ({ ...acc, [item.symbol]: item.pricePrecision }),
    {}
  );

  const quoteAssets = response.data.data.reduce(
    (acc: any, item: any) => ({ ...acc, [item.symbol]: item.quoteAsset }),
    {}
  );

  return { options, precision, quoteAssets };
};

export const fetchExchangeAlgorithms = async ({
  url,
  exchangeId,
  token,
}: {
  url: string;
  exchangeId: string;
  token: string;
}): Promise<Record<string, string>> => {
  const response = await axios.get(`${url}/${exchangeId}`, {
    params: {
      offset: 0,
      limit: 9999,
    },
    headers: { authorization: token },
  });

  if (!response?.data?.data || !response.data.data.length) return {};

  const options = response.data.data.sort((a: any, b: any) => a?.name?.localeCompare(b?.name));
  return { options };
};

export const fetchExchangePriceSymbol = async ({
  stockExchangeId,
  symbol,
  token,
}: {
  stockExchangeId: string;
  symbol: string;
  token: string;
}): Promise<IExchangePriceSymbol> =>
  axios.get(`/v1/stock-exchange/${stockExchangeId}/price/${symbol}`, {
    headers: { authorization: token },
  });

export const getExchangePrompt = async ({
  data,
  token = '',
}: {
  data: Record<string, any>;
  token?: string;
}) => {
  const parsedData = {
    ...pick(['stockExchangeId', 'algorithmId', 'symbol'], data),
    algorithmSettings: pick(
      ['upperPrice', 'lowerPrice', 'gridQuantity', 'investmentAmount', 'stopLossPercent'],
      data
    ),
  };

  const result = await axios.post('/v1/user/exchange/prompt', parsedData, {
    headers: { authorization: token },
  });

  return result?.data?.data;
};

export const createExchangeItem = async ({
  url,
  token,
  data,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const sendNotifications = has('sendNotifications', data) ? data.sendNotifications : true;

  const prompt = await getExchangePrompt({ data, token });

  if (prompt?.minInvestmentAmount == null)
    throw new Error(`Can't fetch prompt data for current transaction`);

  if (prompt.minInvestmentAmount > data.investmentAmount)
    throw new Error(
      `Investment amount should be greater or equal to ${prompt.minInvestmentAmount}`
    );

  const result = await axios.post(
    url,
    {
      customerId: data.customerId,
      stockExchangeId: data.stockExchangeId,
      algorithmId: data.algorithmId,
      symbol: data.symbol,
      sendNotifications,
      allowMarketBuyAsset: data.allowMarketBuyAsset,
      algorithmSettings: {
        upperPrice: data.upperPrice,
        lowerPrice: data.lowerPrice,
        gridQuantity: data.gridQuantity,
        investmentAmount: data.investmentAmount,
        trailingUp: data.trailingUp,
        lotQuantity: data.lotQuantity,
        startQuantity: data.startQuantity,
        priceTopCoefficient: data.priceTopCoefficient,
        priceBottomCoefficient: data.priceBottomCoefficient,
        riskManagementCoefficient: data.riskManagementCoefficient,
        slackToken: data.slackToken,
        slackChannel: data.slackChannel,
        orderSide: data.orderSide,
        futureBotCustomConfig: data.futureBotCustomConfig,
        stopLossPercent: data.stopLossPercent,
      },
    },
    {
      headers: { authorization: token },
    }
  );

  return {
    data: result.data,
  };
};

export const deleteExchangeItem = async ({
  url,
  token,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const result = await axios.delete(url, {
    headers: { authorization: token },
  });

  return {
    data: result.data,
  };
};
