import { JWTAuthProvider } from 'icerockdev-admin-toolkit';
import { tokenRefreshFn } from '~/config/auth/api';
import { SignUpWrapper } from '~/config/components/auth/SignUpWrapper';
import { SignInWrapper } from '~/config/components/auth/SignInWrapper';
import { action, flow, observable } from 'mobx';
import { axios } from '~/utils/axios';
import { API_URLS } from '~/config/constants/urls';

export class JWTWithSmsAuthProvider extends JWTAuthProvider {
  @observable loginPhone = '';
  @observable loginToken = '';

  @observable signupPhone = '';
  @observable signupToken = '';

  @action
  sendAuthRequest = (phone: string, password: string) => {
    this.sendAuthRequestCancel();

    this.sendAuthRequestInstance = flow(function* sendAuthRequest(this: JWTWithSmsAuthProvider) {
      if (!this.authRequestFn) return;

      this.isLoading = true;

      try {
        const response = yield axios.post(API_URLS.auth.signin, { phone, password });

        this.parent?.notifications.hideNotification();

        if (!response?.data?.data?.confirmationToken) throw new Error(`Can't login`);

        this.loginPhone = phone;
        this.loginToken = response.data.data.confirmationToken;
        this.parent?.history.push(`/confirm`);
      } catch (e) {
        this.error = e;
        this.parent?.notifications.showError(e.toString());
      } finally {
        this.isLoading = false;
      }
    }).bind(this)();
  };

  @action
  resendLoginCode = async () => {
    try {
      if (!this.loginPhone) throw new Error('No phone number available, please, log in again');

      const result = await axios.post(API_URLS.auth.signinResendSms, { phone: this.loginPhone });

      if (!result?.data?.data?.confirmationToken)
        throw new Error(`Can't resend sms code, please try to log in again`);

      this.loginToken = result.data.data.confirmationToken;

      this.parent?.notifications.showSuccess(`New sms code was sent to your phone number`);
    } catch (e) {
      this.parent?.notifications.showError(e.message);
    }
  };

  @action
  sendLoginConfirm = async (smsCode) => {
    try {
      const confirmationToken = this.loginToken;
      const result = await axios.post(API_URLS.auth.signinConfirm, {
        confirmationToken,
        smsCode,
      });

      if (!result?.data?.data?.accessToken || !result?.data?.data?.refreshToken) {
        throw new Error(`Can't login, please try again`);
      }

      this.tokens = {
        access: result.data.data.accessToken,
        refresh: result.data.data.refreshToken,
      };

      this.loginToken = '';
      this.loginPhone = '';

      await this.getUserProfile();
    } catch (e) {
      this.parent?.notifications.showError(e.message);
    }
  };

  @action
  sendAuthSignup = (data: any) => {
    flow(function* sendAuthPasswRestore(this: JWTWithSmsAuthProvider) {
      this.isLoading = true;

      try {
        const result = yield axios.post(API_URLS.auth.signup, data);

        if (!result?.data?.data?.confirmationToken)
          throw new Error(`Can't register, please, try again`);

        this.signupPhone = data.phone;
        this.signupToken = result.data.data.confirmationToken;

        this.parent?.history.push('/signup/confirm');
      } catch (e) {
        this.error = e;
        this.parent?.notifications.showError(e.toString());
      } finally {
        this.isLoading = false;
      }
    }).bind(this)();
  };

  @action
  sendSignupConfirm = async (smsCode) => {
    try {
      const confirmationToken = this.signupToken;
      const result = await axios.post(API_URLS.auth.signupConfirm, {
        confirmationToken,
        smsCode,
      });

      if (!result?.data?.data?.accessToken || !result?.data?.data?.refreshToken) {
        throw new Error(`Can't signup, please try again`);
      }

      this.tokens = {
        access: result.data.data.accessToken,
        refresh: result.data.data.refreshToken,
      };

      this.signupPhone = '';
      this.signupToken = '';

      await this.getUserProfile();
    } catch (e) {
      this.parent?.notifications.showError(e.message);
    }
  };

  @action
  resendSignupCode = async () => {
    try {
      if (!this.signupPhone) throw new Error('No phone number available, please, log in again');

      const result = await axios.post(API_URLS.auth.signupResendSms, { phone: this.signupPhone });

      if (!result?.data?.data?.confirmationToken)
        throw new Error(`Can't resend sms code, please try to log in again`);

      this.signupToken = result.data.data.confirmationToken;

      this.parent?.notifications.showSuccess(`New sms code was sent to your phone number`);
    } catch (e) {
      this.parent?.notifications.showError(e.message);
    }
  };

  @action
  getUserProfile = async () => {
    try {
      const result = await this.withToken(
        ({ token }) => axios.get(API_URLS.auth.profile, { headers: { authorization: token } }),
        {}
      );

      if (!result?.data?.data) return;

      this.user = result.data.data;
    } catch (e) {
      this.parent?.notifications.showError(e.message);
    }
  };
}

export default new JWTWithSmsAuthProvider({
  loginLabel: 'Phone number',
  authRequestFn: () => Promise.resolve({} as any),
  authSignupFn: () => Promise.resolve({} as any),
  tokenRefreshFn,
  getUserName: (auth) => [auth.user.firstName, auth.user.lastName].join(' '),
  getUserRoleTitle: (auth) => auth.user.email || '',
  signUp: SignUpWrapper,
  signIn: SignInWrapper,
});
