import React, { FC, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { IEntityFieldProps, useEntity } from 'icerockdev-admin-toolkit';
import { TextField } from '@material-ui/core';
import { ExchangeEntity } from '~/config/pages/exchange/components/ExchangeEntity';
import debounce from 'lodash.debounce';
// @ts-ignore

interface IProps extends IEntityFieldProps {}

const ExchangeGridQuantity: FC<IProps> = observer(({ value, ...props }) => {
  const entity = useEntity<ExchangeEntity>();
  const [isShow, setIsShow] = useState(false);

  const [inputValue, setInputValue] = useState(value);

  const onInputChange = (e) => setInputValue(e.target.value);

  const onValueChange = (value) => {
    if (!props.handler) return;

    props.handler(value);
  };

  const delayedOnValueChange = useCallback(
    debounce(() => onValueChange(inputValue), 500),
    [inputValue]
  );

  useEffect(() => {
    delayedOnValueChange();
    return delayedOnValueChange.cancel;
  }, [delayedOnValueChange, value]);

  useEffect(() => {
    setIsShow(false);
    entity.settings.forEach((el) => {
      if (el.fieldName === props.name) {
        setIsShow(true);
      }
    });
  }, [entity.settings, props.name]);

  const validateInput = (e) => {
    if (e.which === 8) {
      return;
    }
    if (e.which < 46 || e.which > 57 || e.which === 47) {
      e.preventDefault();
    }
  };

  if (!isShow) return null;

  return (
    <TextField
      id="outlined-number"
      label={props.label}
      type="number"
      value={inputValue || ''}
      onChange={onInputChange}
      onKeyPress={validateInput}
      placeholder={props.label}
      InputLabelProps={{
        shrink: true,
      }}
      variant="outlined"
      inputProps={{
        step: 'any',
      }}
      error={!!props.error}
    />
  );
});

export { ExchangeGridQuantity };
