import React, { FC, useCallback } from 'react';
import { observer } from 'mobx-react';
import { SmsCodeConfirm } from '~/config/components/auth/SmsCodeConfirm';
import { useConfig } from 'icerockdev-admin-toolkit';
import { JWTWithSmsAuthProvider } from '~/config/auth';

interface IProps {}

const SignInConfirm: FC<IProps> = observer(() => {
  const config = useConfig();
  const auth = config.auth as JWTWithSmsAuthProvider;
  const phone = auth.loginPhone;
  const token = auth.loginToken;

  // If no phone or token info available, redirect to login
  if (!phone || !token) config.history.push('/');

  return (
    <SmsCodeConfirm id={token} onResend={auth.resendLoginCode} onConfirm={auth.sendLoginConfirm} />
  );
});

export { SignInConfirm };
