import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { Route, Switch } from 'react-router-dom';
import { SignInConfirm } from '~/config/components/auth/SignInConfirm';
import { SignIn } from '../SignIn';

interface IProps {}

const SignInWrapper: FC<IProps> = observer(() => (
  <Switch>
    <Route path="/confirm" component={SignInConfirm} />
    <Route path="/" component={SignIn} />
  </Switch>
));

export { SignInWrapper };
