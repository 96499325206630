import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { Switch, Route } from 'react-router-dom';
import { SignUp } from '~/config/components/auth/SignUp';
import { SignUpConfirm } from '~/config/components/auth/SignUpConfirm';

interface IProps {}

const SignUpWrapper: FC<IProps> = observer(() => (
  <Switch>
    <Route path="/signup/confirm" component={SignUpConfirm} />
    <Route path="/signup" component={SignUp} />
  </Switch>
));

export { SignUpWrapper };
