import {
  Entity,
  IEntityCreateFunctionProps,
  IEntityCreateFunctionResult,
  IEntityFetchFunction,
  IEntityGetFunction,
  IEntityGetFunctionProps,
} from 'icerockdev-admin-toolkit';
import HttpStatusCode from 'http-status-typed';
import { parseResponseErrorMessage } from '~/utils/parse';
import { axios } from '~/utils/axios';

export const fetchStockExchangeItems: IEntityFetchFunction = async ({
  url,
  token,
  page = 0,
  count,
  sortDir,
  sortBy,
  filter,
}) => {
  const offset = page && count ? page * count : 0;
  const limit = count ? (page + 1) * count : 25;
  const orderBy = sortDir.toUpperCase();
  const filters: Record<string, string> =
    filter?.reduce((obj, item) => ({ ...obj, [item.name]: item.value }), {}) || {};

  // fetching orders
  const response = await axios.get(url, {
    params: {
      offset,
      limit,
      ...(sortBy ? { sortBy, orderBy } : {}),
      ...filters,
    },
    headers: { authorization: token },
  });

  const list = response.data.data;

  return {
    data: {
      list,
      totalCount: parseInt(response.headers['x-total-count'] || 0, 10),
    },
    filterData: (list && list[0]) || {}, // we use it for salesUserList, teamLeadUserList and responsibleUserList
    error: '',
  };
};

const getStockExchangeItemData = async ({ url, id, token }: IEntityGetFunctionProps) =>
  axios.get(`${url}/${id}`, {
    headers: {
      authorization: token,
    },
  });

export const getExchangeReports = async ({
  url,
  id,
  token,
  limit,
  offset,
}: IEntityGetFunctionProps & { limit: number; offset: number }) =>
  axios.get(`${url}/report/${id}`, {
    headers: {
      authorization: token,
    },
    params: {
      limit,
      offset,
    },
  });

export const getStockExchangeItem: IEntityGetFunction = async ({ url, id, token }) => {
  const result = await getStockExchangeItemData({ url, id, token });

  if (result?.status !== HttpStatusCode.OK || !result?.data)
    return {
      data: {
        list: [],
        totalCount: 0,
      },
      error: parseResponseErrorMessage(result) || `Can't load data`,
    };

  return {
    data: result.data.data,
    error: '',
  };
};

export const createStockExchangeItem = async ({
  url,
  token,
  data,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const result = await axios.post(
    url,
    {
      customerId: data.customerId,
      stockExchangeId: data.stockExchangeId,
      apiCredentials: {
        apiKey: data.apiKey,
        apiSecret: data.apiSecret,
      },
    },
    {
      headers: { authorization: token },
    }
  );

  if (result?.status !== HttpStatusCode.OK || !result?.data) {
    const error = parseResponseErrorMessage(result) || `Can't create item`;

    return { data: {}, error };
  }

  return {
    data: result.data,
  };
};

export const deleteStockExchangeItem = async ({
  url,
  token,
}: IEntityCreateFunctionProps): Promise<IEntityCreateFunctionResult> => {
  const result = await axios.delete(url, {
    headers: { authorization: token },
  });

  if (result?.status !== HttpStatusCode.OK || !result?.data) {
    const error = parseResponseErrorMessage(result) || `Can't delete item`;

    return { data: {}, error };
  }

  return {
    data: result.data,
  };
};

export const fetchStockExchangeList = (url: string) => async (
  entity: Entity
): Promise<Record<string, string>> => {
  const withToken = entity.parent?.auth?.withToken;

  if (!withToken) return {};

  const response = await withToken(
    ({ token }: { token: string }) =>
      axios.get(url, {
        params: {
          offset: 0,
          limit: 9999,
        },
        headers: { authorization: token },
      }),
    {}
  );

  if (!response?.data?.data || !response.data.data.length) return {};

  return response.data.data
    .sort((a: any, b: any) => a?.name?.localeCompare(b?.name))
    .reduce(
      (obj: Record<string, string>, item: { name: string; id: number }) => ({
        ...obj,
        [item.id]: item.name,
      }),
      {}
    );
};
