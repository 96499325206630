import React, { FC, FormEvent, useCallback, useMemo, useState } from 'react';
import { Button, InputAdornment, TextField } from '@material-ui/core';

import styles from 'icerockdev-admin-toolkit/src/containers/login/SignIn/styles.module.scss';
import { Link } from 'react-router-dom';
import { useConfig } from 'icerockdev-admin-toolkit/dist/application/utils/hooks/mobx';
// @ts-ignore
import ReactPhoneInput from 'react-phone-input-material-ui';

const SignIn: FC = () => {
  const config = useConfig();
  const auth = config.auth;

  const onForgotPassword = useMemo(
    () =>
      config.auth?.authPasswRestoreFn
        ? () => {
            config.history.push('/restore');
          }
        : undefined,
    [config.history, config.auth, config.auth?.authPasswRestoreFn]
  );

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onSubmitCapture = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      auth!!.sendAuthRequest(email, password);
    },
    [email, password, auth]
  );

  const onEmailChange = useCallback((val) => {
    setEmail(val);
  }, []);

  const onPasswordChange = useCallback((event) => setPassword(event.target.value), [setPassword]);

  const loginLabel = useMemo(() => config.auth?.loginLabel || 'Login', [config.auth]);

  return (
    <div className={styles.wrap}>
      <form noValidate onSubmit={onSubmitCapture} className={styles.form}>
        <h3 className={styles.header}>Sign in</h3>

        <ReactPhoneInput
          value={email}
          inputProps={{
            variant: 'filled',
            id: 'email',
            name: 'email',
            label: loginLabel,
            placeholder: '+7 (132) 546-87-99',
            required: true,
            margin: 'normal',
          }}
          onChange={onEmailChange}
          component={TextField}
          specialLabel={null}
        />

        <TextField
          variant="filled"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          defaultValue={password}
          onChange={onPasswordChange}
          autoComplete="current-password"
          InputProps={{
            endAdornment: onForgotPassword ? (
              <InputAdornment position="end" onClick={onForgotPassword} className={styles.forgot}>
                Forgot password?
              </InputAdornment>
            ) : null,
          }}
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={!email.length || !password.length}
          className={styles.button}
        >
          Sign in
        </Button>

        {!!auth?.authSignupFn && (
          <Button
            type="button"
            fullWidth
            variant="outlined"
            color="primary"
            className={styles.button}
            component={Link}
            to="/signup"
          >
            Sign up
          </Button>
        )}
      </form>
    </div>
  );
};

export { SignIn };
