import { Entity } from 'icerockdev-admin-toolkit';
import { action, computed } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { EntityFooter } from '~/config/components/EntityFooter';
import { EntityHead } from '~/config/components/EntityHead';
import { deleteStockExchangeItem } from '~/config/pages/stockExchange/api';
import { EntityViewer } from '~/config/components/EntityCreateViewer';

class StockExchangeEntity extends Entity {
  @action
  deleteItem = async (id: number) => {
    if (!this.parent?.auth?.withToken) return;

    this.isLoading = true;

    await this.parent.auth.withToken(deleteStockExchangeItem, {
      url: `${this?.api?.delete.url}/${id}`,
    });
    await this.fetchItems();

    this.isLoading = true;
  };

  @computed
  get ListHead() {
    return observer(() => (
      <EntityHead
        filterData={this.filterData}
        title={<this.ListHeadTitle />}
        buttons={<this.ListHeadButtons />}
        filters={this.filters}
        fields={this.fields}
        setFilters={this.setFilters}
        url={this.menu.url}
        applyFilter={this.applyFilter}
        withToken={this.parent?.auth?.withToken}
        onExport={this.exportData}
        canExport={this.exportable}
        canCreate={this.creatable && this.canCreate}
        entity={this}
      />
    ));
  }

  @computed
  get ListFooter() {
    return observer(() => (
      <EntityFooter
        page={this.page}
        itemsPerPage={this.itemsPerPage}
        items={this.items}
        totalCount={this.totalCount}
        setPage={this.setPage}
        setPerPage={this.setPerPage}
      />
    ));
  }

  @computed
  get CreatorBody() {
    return observer(() => (
      <EntityViewer
        fields={this.fields}
        errors={this.editorFieldErrors}
        url={this.menu.url}
        onSave={this.createItem}
        onCancel={this.onEditCancel}
        onResetFieldError={this.resetFieldError}
        isEditing
        isLoading={this.isLoading}
        setEditorData={this.setEditorData}
        data={this.editorData}
        getItem={this.createEmptyItem}
        cancelGetItem={this.getItemsCancel}
        viewable={this.viewable}
        withToken={this.parent?.auth?.withToken}
        entity={this}
      />
    ));
  }
}

export { StockExchangeEntity };
