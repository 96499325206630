import React, { useMemo } from 'react';
import styles from './styles.module.scss';
import { EntityField, IEntityField } from 'icerockdev-admin-toolkit';
import cn from 'classnames/bind';
import { ExchangeListCustomCell } from '~/config/pages/exchange/components/ExchangeListCustomCell';

type IColumn = {
  title: string;
  rows: IEntityField[];
};

const COLUMNS: IColumn[] = [
  {
    title: 'Additional information',
    rows: [
      { type: 'number', label: 'Bot ID:', name: 'id' },
      { type: 'datetime', label: 'Date:', name: 'createdAt' },
      { type: 'string', label: 'Algorithm:', name: 'algorithmName' },
      {
        type: 'custom',
        label: 'Init total amount in quote asset:',
        name: 'initTotalAmountInQuoteAsset',
        component: (props) =>
          ExchangeListCustomCell({
            ...props,
            rows: [
              {
                value: 'initTotalAmountInQuoteAsset',
                postfix: ` ${props.data.quoteAsset}`,
              },
            ],
          }),
      },
      {
        type: 'custom',
        label: 'Current total amount in quote asset:',
        name: 'currentTotalAmountInQuoteAsset',
        component: (props) =>
          ExchangeListCustomCell({
            ...props,
            rows: [
              {
                value: 'initTotalAmountInQuoteAsset',
                postfix: ` ${props.data.quoteAsset}`,
              },
            ],
          }),
      },
    ],
  },
  {
    title: 'Settings',
    rows: [
      {
        type: 'custom',
        label: 'Upper price:',
        name: 'upperPrice',
        component: (props) =>
          ExchangeListCustomCell({
            ...props,
            rows: [
              {
                value: 'algorithmSettingsResponse.upperPrice',
                postfix: ` ${props.data.quoteAsset}`,
              },
            ],
          }),
      },
      {
        type: 'custom',
        label: 'Lower price:',
        name: 'lowerPrice',
        component: (props) =>
          ExchangeListCustomCell({
            ...props,
            rows: [
              {
                value: 'algorithmSettingsResponse.lowerPrice',
                postfix: ` ${props.data.quoteAsset}`,
              },
            ],
          }),
      },
      {
        type: 'number',
        label: 'Grid quantity:',
        name: 'gridQuantity',
        component: (props) =>
          ExchangeListCustomCell({
            ...props,
            rows: [
              {
                value: 'algorithmSettingsResponse.gridQuantity',
              },
            ],
          }),
      },
      {
        type: 'number',
        label: 'Grid spacing:',
        name: 'gridSpacing',
        component: (props) =>
          ExchangeListCustomCell({
            ...props,
            rows: [
              {
                value: 'algorithmSettingsResponse.gridSpacing',
              },
            ],
          }),
      },
      {
        type: 'custom',
        label: 'Trailing up:',
        name: 'trailingUp',
        component: (props) =>
          ExchangeListCustomCell({
            ...props,
            rows: [
              {
                value: 'algorithmSettingsResponse.trailingUp',
              },
            ],
          }),
      },
    ],
  },
];

const cx = cn.bind(styles);

const ExchangeListRowExtra = ({ id, data }) => {
  const rowData = useMemo(() => data.find((el) => el.id === id), [data, id]);

  return (
    <div className={cx('additional-information')}>
      {COLUMNS.map((column) => (
        <div className={cx('additional-information__column')} key={column.title}>
          <span>{column.title}</span>
          {column.rows.map((row) => (
            <div className={cx('additional-information__row')} key={row.name}>
              <span>{row.label}</span>
              <EntityField name={row.name} data={rowData} fields={column.rows} />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export { ExchangeListRowExtra };
