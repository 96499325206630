export const isPositiveOrZero = (val: any) => {
  if (val == undefined) {
    return '';
  }

  return val && parseFloat(val) && parseFloat(val) > 0
    ? ''
    : 'Значение должно быть больше, либо равно 0';
};

export const isPositiveOrEmpty = (val: any) =>
  !val || (parseFloat(val) && parseFloat(val) > 0)
    ? ''
    : 'Значение должно быть больше, либо равно 0';
