import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  spacing: 4,
  overrides: {
    MuiGrid: {
      root: {
        'min-height': '100px',
      },
    },
  },
});
