import Axios from 'axios';
import { jwtExpired } from './catcher';
import qs from 'query-string';

if (!process.env.REACT_APP_API_URL)
  throw new Error('You should create .env file with REACT_APP_API_URL=https://your.ai.url');

export const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: 'none' });
  },
});

axios.interceptors.response.use((response) => response, jwtExpired);
