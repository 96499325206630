import React, { FC, useCallback, useState } from 'react';
import styles from './styles.module.scss';
import { Button } from '@material-ui/core';
import { useConfig } from 'icerockdev-admin-toolkit';
import { AuthTextInput } from '~/config/components/auth/AuthTextInput';
import { useErrorsWithClearOnInput } from '~/utils/hooks';
import { isEmail } from 'validator';
import { Link } from 'react-router-dom';

const SignUp: FC = () => {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');

  const config = useConfig();
  const auth = config.auth!!;
  const [errors, setErrors, validate] = useErrorsWithClearOnInput(
    {
      email,
      phone,
      password,
      lastName,
      firstName,
      middleName,
      passwordRepeat,
    },
    'Required field',
    'Fill in all the fields provided'
  );

  const onSubmit = useCallback(
    (event) => {
      try {
        event.preventDefault();
        validate();

        if (password.length < 6) {
          setErrors({ ...errors, password: 'Too short' });
          throw new Error('Password must be longer than 6 characters');
        }

        if (password !== passwordRepeat) {
          setErrors({ ...errors, passwordRepeat: "Doesn't match password" });
          throw new Error('Password mismatch');
        }

        if (!isEmail(email)) {
          setErrors({ ...errors, email: 'Invalid email format' });
          throw new Error('Invalid email format');
        }

        auth.sendAuthSignup({
          email: email.trim(),
          phone: phone.replace(/[^\d]/gim, '').trim(),
          password,
          passwordRepeat,
          lastName,
          firstName,
          middleName,
        });
      } catch (e) {
        config.notifications.showError(e.message);
      }
    },
    [
      validate,
      password,
      passwordRepeat,
      email,
      auth,
      phone,
      lastName,
      firstName,
      middleName,
      setErrors,
      errors,
      config.notifications,
    ]
  );

  return (
    <form className={styles.form} onSubmit={onSubmit}>
      <h3 className={styles.header}>Регистрация</h3>

      <AuthTextInput
        label="Phone number"
        value={phone}
        handler={setPhone}
        autoComplete="phone"
        err={errors.phone}
      />

      <AuthTextInput
        label="Email"
        value={email}
        handler={setEmail}
        autoComplete="email"
        err={errors.email}
      />

      <AuthTextInput
        label="Last name"
        value={lastName}
        handler={setLastName}
        err={errors.lastName}
      />

      <AuthTextInput
        label="First name"
        value={firstName}
        handler={setFirstName}
        err={errors.firstName}
      />

      <AuthTextInput
        label="Middle name"
        value={middleName}
        handler={setMiddleName}
        err={errors.middleName}
      />

      <AuthTextInput
        label="Password"
        value={password}
        handler={setPassword}
        autoComplete="new-password"
        err={errors.password}
        type="password"
      />

      <AuthTextInput
        label="Repeat password"
        value={passwordRepeat}
        handler={setPasswordRepeat}
        type="password"
        autoComplete="new-password"
        err={errors.passwordRepeat}
      />

      <Button type="submit" fullWidth variant="contained" color="primary" className={styles.button}>
        Sign up
      </Button>

      <Button
        type="button"
        fullWidth
        variant="outlined"
        color="primary"
        className={styles.button}
        component={Link}
        to="/"
      >
        Cancel
      </Button>
    </form>
  );
};

export { SignUp };
