import { IEntityFieldProps } from 'icerockdev-admin-toolkit/dist/application/modules/pages/Entity/types';
import React, { FC, useMemo } from 'react';
import { EntityField } from 'icerockdev-admin-toolkit';

type IProps = IEntityFieldProps & {};

const BINANCE_FUTURES_ID = '2';

const ExchangeFuturesInput: FC<IProps> = (props) => {
  const isShow = useMemo(() => props.data?.stockExchangeId === BINANCE_FUTURES_ID, [props.data]);
  const fields = useMemo(
    () =>
      props.fields.map((field) =>
        field.name === props.name ? { ...field, component: undefined } : field
      ),
    [props.fields, props.name]
  );

  if (!isShow) return null;

  return <EntityField {...props} fields={fields} />;
};

export { ExchangeFuturesInput };
