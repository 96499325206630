import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { EntityFieldString, IEntityFieldProps } from 'icerockdev-admin-toolkit';
import { observer } from 'mobx-react';
import { ExchangeEntity } from '~/config/pages/exchange/components/ExchangeEntity';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

export const ExchangeAlgorithmField: FC<IEntityFieldProps> = observer(
  ({ entity, isEditing, ...props }) => {
    useEffect(() => {
      if (!entity || !isEditing) return;

      const exchangeId = entity?.editorData?.stockExchangeId;

      if (!exchangeId) return;

      const query = (entity as ExchangeEntity).getExchangeAlgorithms(
        entity.editorData?.stockExchangeId
      );

      return () => query?.cancel();
    }, [entity, entity?.editorData?.stockExchangeId, isEditing]);

    // const variants = Object.entries(props.options || {})
    // .filter((el) => el)
    // .map(([id, name]) => ({ id: name.id, name: name.name })) || []

    // .map(([id, name]) => ({ id, name })) || []
    // const variants = (Array.isArray(props.options) && props.options.length) ? props.options.map(([id, name, settings]) => ({ id: String(id), name, settings })) : []
    const variants = useMemo<{ id: string; name: string; algorithmId: string }[]>(() => {
      return (
        Object.entries(props.options || {})
          .filter((el) => el)
          .map(([id, option]) => ({
            id: option.name,
            name: option.name,
            settings: option.settings,
            algorithmId: option.id.toString(),
          })) || []
      );
    }, [props.options]);

    const onChange = useCallback(
      (_, val: { id: string; name: string; algorithmId: string; settings: Array<any> } | null) => {
        if (!props.handler || !val) return;
        props.handler(val.algorithmId);
        (entity as ExchangeEntity).setSettings(val.settings);
      },
      [props]
    );

    if (!isEditing) return <EntityFieldString entity={entity} isEditing={isEditing} {...props} />;

    const getLabel = useCallback(
      (option) =>
        typeof option === 'string'
          ? variants.find((el) => el.algorithmId === option)?.name
          : option.name,
      [variants]
    );

    return (
      <Autocomplete
        disableClearable
        value={props.value}
        getOptionLabel={getLabel}
        getOptionSelected={getLabel}
        options={variants}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={props.label || props.name}
            placeholder="Поиск"
            error={!!props.error}
          />
        )}
      />
    );
  }
);
