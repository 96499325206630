import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { EntityFieldString, IEntityFieldProps } from 'icerockdev-admin-toolkit';
import { observer } from 'mobx-react';
import { ExchangeEntity } from '~/config/pages/exchange/components/ExchangeEntity';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

const getOption = (option) => (typeof option === 'string' ? option : option.title);

const ExchangeSymbolField: FC<IEntityFieldProps> = observer(({ entity, isEditing, ...props }) => {
  useEffect(() => {
    if (!entity || !isEditing) return;

    const exchangeId = entity?.editorData?.stockExchangeId;

    if (!exchangeId) return;

    const query = (entity as ExchangeEntity).getExchangeSymbols(entity.editorData?.stockExchangeId);

    return () => query?.cancel();
  }, [entity, entity?.editorData?.stockExchangeId, isEditing]);

  const variants = useMemo<{ id: string; title: string }[]>(() => {
    return (
      Object.entries(props.options || {})
        .filter((el) => el)
        .map(([id, title]) => ({ id, title })) || []
    );
  }, [props.options]);

  const onChange = useCallback(
    (_, val: { id: string; title: string } | null) => {
      if (!props.handler || !val) return;

      props.handler(val.id);

      (entity as ExchangeEntity).getExchangePriceSymbol(
        entity?.editorData?.stockExchangeId,
        entity?.editorData?.symbol
      );
    },
    [props]
  );

  if (!isEditing) return <EntityFieldString entity={entity} isEditing={isEditing} {...props} />;

  return (
    <Autocomplete
      disableClearable
      value={props.value}
      getOptionLabel={getOption}
      getOptionSelected={getOption}
      options={variants}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={props.label || props.name}
          placeholder="Поиск"
          error={!!props.error}
        />
      )}
    />
  );
});

export { ExchangeSymbolField };
