import {
  createStockExchangeItem,
  fetchStockExchangeItems,
  fetchStockExchangeList,
  getStockExchangeItem,
} from './api';
import { STOCK_EXCHANGE_FIELDS } from './fields';
import { StockExchangeEntity } from '~/config/pages/stockExchange/components/StockExchangeEntity';

export default new StockExchangeEntity({
  title: 'Stock Exchange',
  viewable: false,
  editable: false,
  creatable: true,
  exportable: false,
  items: 50,
  api: {
    list: { url: `/v1/user/stock-exchange`, method: 'get' },
    get: { url: `/v1/user/stock-exchange`, method: 'get' },
    create: { url: `/v1/user/stock-exchange`, method: 'post' },
    delete: { url: `/v1/user/stock-exchange`, method: 'delete' },
  },
  menu: {
    label: 'Stock Exchange',
    url: '/stock-exchange',
    enabled: true,
  },
  references: {
    stockExchangeId: {
      getMany: fetchStockExchangeList('/v1/stock-exchange'),
    },
  },
  fields: STOCK_EXCHANGE_FIELDS,
  fetchItemsFn: fetchStockExchangeItems,
  getItemsFn: getStockExchangeItem,
  createItemsFn: createStockExchangeItem,
});
