import React, { FC, useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useEntity, IEntityFieldProps } from 'icerockdev-admin-toolkit';
import { ExchangeEntity } from '~/config/pages/exchange/components/ExchangeEntity';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel } from '@material-ui/core';

interface IProps extends IEntityFieldProps {}

export const ExchangeCheckbox: FC<IProps> = observer(({ value, ...props }) => {
  const [isShow, setIsShow] = useState(false);
  const entity = useEntity<ExchangeEntity>();

  useEffect(() => {
    setIsShow(false);
    entity.settings.forEach((el) => {
      if (el.fieldName === props.name) {
        setIsShow(true);
      }
    });
  }, [entity.settings, props.name]);

  const onChange = useCallback(
    (event) => {
      if (!props.handler) return;

      props.handler(event.target.checked);
    },
    [props]
  );

  if (!isShow) return null;

  return (
    <FormControlLabel
      control={<Checkbox checked={value || false} onChange={onChange} name={props.name} />}
      label={props.label}
    />
  );
});
