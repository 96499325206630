import React, { FC, useCallback } from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';

type IProps = TextFieldProps & {
  label: string;
  value;
  handler: (val: string) => void;
  type?: 'text' | 'password';
  err?: string;
};

const AuthTextInput: FC<IProps> = ({ label, value, handler, type = 'text', err, ...props }) => {
  const onChange = useCallback((event) => handler(event.target.value), [handler]);

  return (
    <TextField
      variant="filled"
      margin="normal"
      fullWidth
      label={label}
      value={value}
      onChange={onChange}
      type={type}
      error={!!err}
      helperText={err}
      {...props}
    />
  );
};

export { AuthTextInput };
