import { IEntityField } from 'icerockdev-admin-toolkit';
import { ExchangeItemViewer } from '~/config/pages/exchange/view/ExchangeItemViewer';
import { ExchangeSymbolField } from '~/config/pages/exchange/components/ExchangeSymbolField';
import { ExchangeAlgorithmField } from '~/config/pages/exchange/components/ExchangeAlgorithmField';
import { isPositiveOrZero } from '~/utils/validators';
import { ORDER_SIDE_LIST } from '~/config/pages/exchange/constants';
import { ExchangeCancelButton } from '~/config/pages/exchange/components/ExchangeCancelButton';
import { ExchangeAmountInput } from '~/config/pages/exchange/components/ExchangeAmountInput';
import { ExchangeCheckbox } from '~/config/pages/exchange/components/ExchangeCheckbox';
import { ExchangeGridQuantity as ExchangeQuantity } from '~/config/pages/exchange/components/ExchangeGridQuantity';
import { ExchangeTextarea } from '~/config/pages/exchange/components/ExchangeTextarea';
import { ExchangeListCustomCell } from '~/config/pages/exchange/components/ExchangeListCustomCell';
import { ExchangeFuturesInput } from '~/config/pages/exchange/components/ExchangeFuturesInput';
import { ExchangePassiveStatus } from '~/config/pages/exchange/components/ExchangePassiveStatus';

export const EXCHANGE_FIELDS: IEntityField[] = [
  // passive status
  {
    type: 'custom',
    name: '',
    label: '',
    hideInView: true,
    hideInEdit: true,
    hideInCreate: true,
    component: ExchangePassiveStatus,
  },
  {
    type: 'number',
    name: 'id',
    label: 'Id',
    hideInView: true,
    hideInEdit: true,
    hideInCreate: true,
    sortable: true,
  },
  {
    type: 'datetime',
    name: 'createdAt',
    label: 'Date / Time',
    hideInView: true,
    hideInEdit: true,
    hideInCreate: true,
    sortable: true,
  },
  {
    type: 'string',
    name: 'symbolStockExchangeName',
    label: 'Symbol / Stock',
    hideInView: true,
    hideInEdit: true,
    hideInCreate: true,
    component: (props) =>
      ExchangeListCustomCell({
        ...props,
        rows: [{ value: 'baseAsset / quoteAsset' }, { value: 'stockExchangeName' }],
      }),
  },
  {
    type: 'string',
    name: 'priceOnCreateAndCurrentPrice',
    label: 'Price on create / Current price',
    hideInView: true,
    hideInEdit: true,
    hideInCreate: true,
    component: (props) =>
      ExchangeListCustomCell({
        ...props,
        rows: [{ value: 'algorithmSettingsResponse.currentPrice' }, { value: 'currentPrice' }],
      }),
  },
  {
    type: 'string',
    name: 'upLowPrice',
    label: 'Up / Low price',
    hideInView: true,
    hideInEdit: true,
    hideInCreate: true,
    component: (props) =>
      ExchangeListCustomCell({
        ...props,
        rows: [
          { value: 'algorithmSettingsResponse.upperPrice' },
          { value: 'algorithmSettingsResponse.lowerPrice' },
        ],
      }),
  },
  {
    type: 'string',
    name: 'gridQuantity',
    label: 'Grid',
    hideInView: true,
    hideInEdit: true,
    hideInCreate: true,
    component: (props) =>
      ExchangeListCustomCell({
        ...props,
        rows: [{ value: 'algorithmSettingsResponse.gridQuantity' }],
      }),
  },
  {
    type: 'string',
    name: 'investmentAmount',
    label: 'Investment amount',
    hideInView: true,
    hideInEdit: true,
    hideInCreate: true,
    component: (props) =>
      ExchangeListCustomCell({
        ...props,
        rows: [{ value: 'algorithmSettingsResponse.investmentAmount' }],
      }),
  },
  {
    type: 'string',
    name: 'dayProfitInQuoteAsset',
    label: '24h Profit In Quote Asset',
    hideInEdit: true,
    hideInCreate: true,
    component: (props) =>
      ExchangeListCustomCell({
        ...props,
        rows: [
          { value: 'dayProfitInQuoteAsset' },
          { value: 'dayProfitInQuoteAssetPercent', postfix: '%' },
        ],
      }),
  },
  {
    type: 'string',
    name: 'totalProfitInQuoteAsset',
    label: 'Total Profit In Quote Asset',
    hideInEdit: true,
    hideInCreate: true,
    component: (props) =>
      ExchangeListCustomCell({
        ...props,
        rows: [
          { value: 'totalProfitInQuoteAsset' },
          { value: 'totalProfitInQuoteAssetPercent', postfix: '%' },
        ],
      }),
  },
  {
    type: 'string',
    name: 'totalPnl',
    label: 'Total PNL',
    hideInEdit: true,
    hideInCreate: true,
    component: (props) =>
      ExchangeListCustomCell({
        ...props,
        rows: [{ value: 'totalPnl' }, { value: 'totalPnlPercent', postfix: '%' }],
      }),
  },
  {
    type: 'number',
    name: 'stopLoss',
    label: 'Stop Loss',
    component: (props) =>
      ExchangeListCustomCell({
        ...props,
        rows: [
          { value: 'algorithmSettingsResponse.stopLossPrice' },
          { value: 'algorithmSettingsResponse.stopLossPercent', postfix: '%' },
        ],
      }),
    hideInCreate: true,
    hideInEdit: true,
  },
  {
    type: 'string',
    name: 'initTotalAmountInQuoteAsset',
    label: 'Init Total Amount In Quote Asset',
    hideInEdit: true,
    hideInCreate: true,
    hideInList: true,
  },
  {
    type: 'string',
    name: 'currentTotalAmountInQuoteAsset',
    label: 'Current Total Amount In Quote Asset',
    hideInEdit: true,
    hideInCreate: true,
    hideInList: true,
  },
  {
    type: 'string',
    name: 'totalProfitInQuoteAsset',
    label: 'Total Profit In Quote Asset',
    hideInEdit: true,
    hideInCreate: true,
    hideInList: true,
  },
  {
    type: 'string',
    name: 'totalPnl',
    label: 'Total PNL',
    hideInEdit: true,
    hideInCreate: true,
    hideInList: true,
  },
  {
    type: 'string',
    name: 'totalPnlPercent',
    label: 'Total PNL Percent',
    hideInEdit: true,
    hideInCreate: true,
    hideInList: true,
  },

  {
    type: 'custom',
    name: 'itemList',
    label: 'Items',
    component: ExchangeItemViewer,
    hideInList: true,
    hideInEdit: true,
    hideInCreate: true,
  },

  // Editor
  {
    type: 'referenceSelect',
    name: 'stockExchangeId',
    label: 'Stock exchange',
    hideInList: true,
    hideInEdit: true,
    required: true,
  },
  {
    type: 'custom',
    name: 'algorithmId',
    label: 'Algorithm',
    hideInList: true,
    hideInEdit: true,
    component: ExchangeAlgorithmField,
    required: true,
    sortable: true,
  },
  {
    type: 'custom',
    name: 'symbol',
    label: 'Symbol',
    hideInView: true,
    hideInEdit: true,
    hideInList: true,
    component: ExchangeSymbolField,
    required: true,
    sortable: true,
  },
  {
    type: 'custom',
    component: ExchangeAmountInput,
    name: 'upperPrice',
    label: 'Upper price',
    hideInList: true,
    hideInEdit: true,
    validator: isPositiveOrZero,
  },
  {
    type: 'custom',
    component: ExchangeAmountInput,
    name: 'lowerPrice',
    label: 'Lower price',
    hideInList: true,
    hideInEdit: true,
    validator: isPositiveOrZero,
  },
  {
    type: 'custom',
    name: 'stopLossPercent',
    label: 'Stop Loss Percent',
    component: ExchangeQuantity,
    hideInList: true,
    hideInEdit: true,
  },
  {
    type: 'custom',
    name: 'gridQuantity',
    label: 'Grid quantity',
    component: ExchangeQuantity,
    hideInList: true,
    hideInEdit: true,
    validator: isPositiveOrZero,
  },
  {
    type: 'custom',
    component: ExchangeAmountInput,
    name: 'investmentAmount',
    label: 'Investment amount',
    hideInList: true,
    hideInEdit: true,
    validator: isPositiveOrZero,
  },
  {
    type: 'custom',
    component: ExchangeQuantity,
    name: 'lotQuantity',
    label: 'Lot quantity',
    hideInList: true,
    hideInEdit: true,
    validator: isPositiveOrZero,
  },
  {
    type: 'custom',
    component: ExchangeQuantity,
    name: 'startQuantity',
    label: 'Start quantity',
    hideInList: true,
    hideInEdit: true,
    validator: isPositiveOrZero,
  },
  {
    type: 'custom',
    component: ExchangeQuantity,
    name: 'priceTopCoefficient',
    label: 'Coefficient for averaging order',
    hideInList: true,
    hideInEdit: true,
    validator: isPositiveOrZero,
  },
  {
    type: 'custom',
    component: ExchangeQuantity,
    name: 'priceBottomCoefficient',
    label: 'Coefficient for close position order',
    hideInList: true,
    hideInEdit: true,
    validator: isPositiveOrZero,
  },
  {
    type: 'custom',
    component: ExchangeQuantity,
    name: 'riskManagementCoefficient',
    label: 'Coefficient for risk management',
    hideInList: true,
    hideInEdit: true,
    validator: isPositiveOrZero,
  },
  {
    type: 'select',
    name: 'orderSide',
    label: 'Order Side',
    options: ORDER_SIDE_LIST,
    hideInView: true,
    hideInList: true,
    hideInEdit: false,
    hideInCreate: false,
    sortable: true,
    component: ExchangeFuturesInput,
  },
  {
    type: 'string',
    name: 'slackToken',
    label: 'Slack token',
    hideInList: true,
    hideInEdit: true,
    component: ExchangeFuturesInput,
  },
  {
    type: 'string',
    name: 'slackChannel',
    label: 'Slack channel',
    hideInList: true,
    hideInEdit: true,
    component: ExchangeFuturesInput,
  },
  {
    type: 'custom',
    name: 'trailingUp',
    component: ExchangeCheckbox,
    hideInList: true,
  },
  {
    type: 'boolean',
    name: 'allowMarketBuyAsset',
    label: 'Allow to change cryptocurrencies',
    hideInList: true,
  },
  {
    type: 'custom',
    name: 'futureBotCustomConfig',
    label: 'Custom config',
    component: ExchangeTextarea,
    hideInList: true,
    hideInEdit: true,
  },
  // Cancel button
  {
    type: 'custom',
    name: 'cancel',
    label: ' ',
    hideInView: true,
    hideInEdit: true,
    hideInCreate: true,
    component: ExchangeCancelButton,
  },
];
