import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { SmsCodeConfirm } from '~/config/components/auth/SmsCodeConfirm';
import { useConfig } from 'icerockdev-admin-toolkit';
import { JWTWithSmsAuthProvider } from '~/config/auth';

interface IProps {}

const SignUpConfirm: FC<IProps> = observer(() => {
  const config = useConfig();
  const auth = config.auth as JWTWithSmsAuthProvider;
  const phone = auth.signupPhone;
  const token = auth.signupToken;

  // If no phone or token info available, redirect to login
  if (!phone || !token) config.history.push('/');

  return (
    <SmsCodeConfirm
      id={token}
      onResend={auth.resendSignupCode}
      onConfirm={auth.sendSignupConfirm}
    />
  );
});

export { SignUpConfirm };
