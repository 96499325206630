import { Config } from 'icerockdev-admin-toolkit';
import auth from './auth';
import exchange from './pages/exchange';
import stockExchange from './pages/stockExchange';
import theme from './theme';

export default new Config({
  auth,
  pages: [exchange, stockExchange],
  host: process.env.REACT_APP_API_URL,
  theme,
});
