export const API_URLS = {
  auth: {
    signin: '/v1/auth/signin',
    signup: '/v1/auth/signup',
    refresh: '/v1/auth/refresh',
    profile: '/v1/user/profile',
    signinResendSms: `/v1/auth/signin/resend/sms`,
    signinConfirm: `/v1/auth/signin/confirm`,
    signupConfirm: `/v1/auth/signup/confirm`,
    signupResendSms: `/v1/auth/signup/resend/sms`,
  },
  exchange: {
    list: '/v1/user/exchange',
    algorithm: '/v1/user/algorithm',
  },
  stockExchange: {
    list: '/v1/user/stock-exchange',
  },
};
