import {
  createExchangeItem,
  fetchExchangeAlgorithmStock,
  fetchExchangeItems,
  getExchangeItem,
} from './api';
import { EXCHANGE_FIELDS } from './fields';
import { ExchangeEntity } from '~/config/pages/exchange/components/ExchangeEntity';

export default new ExchangeEntity({
  title: 'Exchange',
  viewable: true,
  editable: false,
  creatable: true,
  exportable: false,
  items: 50,
  api: {
    list: { url: `/v1/user/exchange`, method: 'get' },
    get: { url: `/v1/user/exchange`, method: 'get' },
    create: { url: `/v1/user/exchange`, method: 'post' },
    delete: { url: `/v1/user/exchange`, method: 'delete' },
    symbols: { url: `/v1/user/stock-exchange`, method: 'get' },
    algorithms: { url: `/v1/user/algorithm`, method: 'get' },
  },
  menu: {
    label: 'Exchange',
    url: '/exchange',
    enabled: true,
  },
  references: {
    stockExchangeId: {
      getMany: fetchExchangeAlgorithmStock('/v1/stock-exchange'),
    },
  },
  fields: EXCHANGE_FIELDS,
  fetchItemsFn: fetchExchangeItems,
  getItemsFn: getExchangeItem,
  createItemsFn: createExchangeItem,
});
