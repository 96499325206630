import React, { FC, useState } from 'react';
import { EntityModal } from '~/config/components/EntityModal';
import { EntityViewer, IEntityViewer } from '~/config/components/EntityCreateViewer';
import { ENTITY_ERRORS, useEntity } from 'icerockdev-admin-toolkit';

type IProps = IEntityViewer & {
  validateSubmitFields: (data: Record<string, any>, isCreating: boolean) => boolean;
};

const ExchangeCreatorBody: FC<IProps> = (props) => {
  const entity = useEntity();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);

  const handleSave = () => {
    try {
      if (!entity.validateSubmitFields(props.data, true)) {
        throw new Error(ENTITY_ERRORS.INCORRECT_INPUT);
      }
      handleModalOpen();
    } catch (e) {
      entity.error = e;
      entity.parent?.notifications.showError(e.message);
    }
  };

  const handleConfirm = () => {
    props.onSave();
    handleModalClose();
  };

  return (
    <>
      <EntityModal
        title="Are you sure you want to create a bot?"
        open={isModalOpen}
        confirmButtonText="Yes"
        onConfirm={handleConfirm}
        cancelButtonText="No"
        onCancel={handleModalClose}
        onClose={handleModalClose}
      />
      <EntityViewer {...props} onSave={handleSave} />
    </>
  );
};

export { ExchangeCreatorBody };
