import HttpStatusCode from 'http-status-typed';
import { UNAUTHORIZED } from 'icerockdev-admin-toolkit';
import { AxiosError } from 'axios';
import path from 'ramda/es/path';

// Intercepts token expiration and passes UNAUNTHORIZED to refresh it
export const jwtExpired = (e: AxiosError): never => {
  if (e?.response?.status === HttpStatusCode.UNAUTHORIZED) {
    throw new Error(UNAUTHORIZED);
  }

  const validationError = path(['response', 'data', 'data', 0, 'message'], e) as string;

  if (validationError) {
    throw new Error(validationError);
  }

  if ((e?.response as any)?.data?.message) {
    throw new Error((e?.response as any).data.message);
  }

  throw e;
};

export const loginError = (e: AxiosError) => {
  const validationError = path(['response', 'data', 'data', 0, 'message'], e) as string;

  if (validationError) {
    throw new Error(validationError);
  }

  throw new Error(e?.response?.data?.message);
};
