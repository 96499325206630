import React, { FC } from 'react';
import styles from '~/config/pages/exchange/view/ExchangeItemViewer/styles.module.scss';
import { EXCHANGE_STATUSES } from '~/config/pages/exchange/constants';
import { formatDateTime } from '~/utils/date';
import { Placeholder } from '~/config/components/Placeholder';
import { IExchangeItem } from '~/config/pages/exchange/types';

interface IProps {
  data: IExchangeItem;
  isLoading: boolean;
}

const ExchangeItemViewerInfo: FC<IProps> = ({ data, isLoading }) => {
  const showPlaceholder = isLoading && !data;
  const totalPnl = parseFloat(data?.totalPnl?.toString() || '').toFixed(4);
  const pnl = totalPnl ? `${totalPnl} (${data?.totalPnlPercent}%)` : null;

  return (
    <div className={styles.scroll}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Id</th>
            <th>Status</th>
            <th>Customer</th>
            <th>Stock</th>
            <th>Algorithm</th>
            <th>Grid Spacing</th>
            <th>Price on create</th>
            <th>Settings</th>
            <th>Date</th>
            <th>Symbol</th>
            <th>Init Total Amount In Quote Asset</th>
            <th>Current Total Amount In Quote Asset</th>
            <th>Total Profit In Quote Asset</th>
            <th>Total PNL</th>
            <th>Stop Loss</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Placeholder active={showPlaceholder} value={data?.id} />
            </td>
            <td>
              <Placeholder active={showPlaceholder} value={EXCHANGE_STATUSES[data?.status]} />
            </td>
            <td>
              <Placeholder active={showPlaceholder} value={data?.customerName} />
            </td>
            <td>
              <Placeholder active={showPlaceholder} value={data?.stockExchangeName} />
            </td>
            <td>
              <Placeholder active={showPlaceholder} value={data?.algorithmName} />
            </td>
            <td>
              <Placeholder
                active={showPlaceholder}
                value={data?.algorithmSettingsResponse?.gridSpacing}
              />
            </td>
            <td>
              <Placeholder active={showPlaceholder} value={data?.priceOnCreate} />
            </td>
            <td>
              <Placeholder active={showPlaceholder} value={<pre>{data?.algorithmSettings}</pre>} />
            </td>
            <td>
              <Placeholder active={showPlaceholder} value={formatDateTime(data?.createdAt)} />
            </td>
            <td>
              <Placeholder active={showPlaceholder} value={data?.symbol} />
            </td>
            <td>
              <Placeholder active={showPlaceholder} value={data?.initTotalAmountInQuoteAsset} />
            </td>
            <td>
              <Placeholder active={showPlaceholder} value={data?.currentTotalAmountInQuoteAsset} />
            </td>
            <td>
              <Placeholder active={showPlaceholder} value={data?.totalProfitInQuoteAsset} />
            </td>
            <td>
              <Placeholder active={showPlaceholder} value={pnl} />
            </td>
            <td>
              <div>
                <Placeholder
                  active={showPlaceholder}
                  value={data?.algorithmSettingsResponse?.stopLossPrice}
                />
              </div>
              <div>
                <Placeholder
                  active={showPlaceholder}
                  value={data?.algorithmSettingsResponse?.stopLossPercent}
                />{' '}
                %
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export { ExchangeItemViewerInfo };
