import React, { useCallback, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useEntity } from 'icerockdev-admin-toolkit';
import { ExchangeEntity } from '~/config/pages/exchange/components/ExchangeEntity';
import { has } from 'ramda';
import { FormControlLabel, Switch } from '@material-ui/core';
import styles from './styles.module.scss';
import { ExchangeHeadCard } from '~/config/pages/exchange/components/ExchangeHeadCard';
import classNames from 'classnames';

const ExchangeHeadButtons = observer(() => {
  const { editorData, setEditorData, exchangePrompt, isLoading, symbolPrice } = useEntity<
    ExchangeEntity
  >();

  const sendNotifications = useMemo(
    () => (has('sendNotifications', editorData) ? editorData.sendNotifications : true),
    [editorData]
  );

  const onChange = useCallback(() => {
    setEditorData({
      ...editorData,
      sendNotifications: !sendNotifications,
    });
  }, [editorData, sendNotifications, setEditorData]);

  if (isLoading) return <></>;

  return (
    <div className={styles.wrap}>
      <FormControlLabel
        value="start"
        control={<Switch checked={sendNotifications} onChange={onChange} />}
        label="Send notifications"
        labelPlacement="start"
      />

      <div className={styles.prompt}>
        {exchangePrompt?.quoteAsset !== 'USDT' && (
          <ExchangeHeadCard
            label={`Min investment ${exchangePrompt?.quoteAsset}`}
            value={exchangePrompt?.minInvestmentAmount}
          />
        )}

        <ExchangeHeadCard
          label={`Min investment USDT`}
          value={exchangePrompt?.minInvestmentAmountUSDT}
        />

        <ExchangeHeadCard
          label={`Your balance ${exchangePrompt?.quoteAsset}`}
          value={exchangePrompt?.quoteAssetBalance}
        />

        <ExchangeHeadCard
          label={`Your balance ${exchangePrompt?.baseAsset}`}
          value={exchangePrompt?.baseAssetBalance}
        />

        <ExchangeHeadCard label={`Grid spacing`} value={exchangePrompt?.gridSpacing} />

        <ExchangeHeadCard
          label={`Lack of balance ${exchangePrompt.baseAsset}`}
          value={exchangePrompt?.needBalanceInBaseAsset}
          valueClassName={classNames({
            [styles.positive]: exchangePrompt?.needBalanceInBaseAsset == 0,
            [styles.negative]:
              exchangePrompt?.needBalanceInBaseAsset && exchangePrompt?.needBalanceInBaseAsset > 0,
          })}
        />

        <ExchangeHeadCard
          label={`Lack of balance ${exchangePrompt.quoteAsset}`}
          value={exchangePrompt?.needBalanceInQuoteAsset}
          valueClassName={classNames({
            [styles.positive]: exchangePrompt?.needBalanceInQuoteAsset == 0,
            [styles.negative]:
              exchangePrompt?.needBalanceInQuoteAsset &&
              exchangePrompt?.needBalanceInQuoteAsset > 0,
          })}
        />

        <ExchangeHeadCard
          label={`${editorData?.symbol} Price`}
          value={editorData?.symbol && symbolPrice}
        />
      </div>
    </div>
  );
});

export { ExchangeHeadButtons };
