import { IJWTTokenRefreshFn } from 'icerockdev-admin-toolkit';
import { axios } from '../../utils/axios';
import { API_URLS } from '~/config/constants/urls';

export const tokenRefreshFn: IJWTTokenRefreshFn = async (refresh) => {
  const {
    data: {
      data: { accessToken, refreshToken },
    },
  } = await axios.post(
    API_URLS.auth.refresh,
    { refreshToken: refresh },
    { headers: { authorization: `Bearer ${refresh}` } }
  );

  return {
    access: accessToken,
    refresh: refreshToken,
  };
};
