import React, { FC, useCallback, useMemo, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { EntityFieldNumber, IEntityFieldProps, useEntity } from 'icerockdev-admin-toolkit';
import { TextField } from '@material-ui/core';
import { ExchangeEntity } from '~/config/pages/exchange/components/ExchangeEntity';
// @ts-ignore
import { IMaskInput } from 'react-imask';
import debounce from 'lodash.debounce';

interface IProps extends IEntityFieldProps {}

const COMPONENT = ({ inputRef, onChange, ...other }: { inputRef: any; onChange: any }) => (
  <IMaskInput onChange={onChange} {...other} mask={Number} inputRef={inputRef} />
);

const INPUT_PROPS = {
  mask: Number, // enable number mask
  signed: false, // disallow negative
  normalizeZeros: true, // appends or removes zeros at ends
  padFractionalZeros: true, // if true, then pads zeros at end to the length of scale
  radix: '.', // fractional delimiter
  mapToRadix: ['.', ','], // symbols to process as radix
  // also Pattern options can be set
  lazy: false,

  autofix: true,
};
const ExchangeAmountInput: FC<IProps> = observer(({ value, isEditing, isFiltering, ...props }) => {
  const entity = useEntity<ExchangeEntity>();
  const [isShow, setIsShow] = useState(false);
  const symbol = useMemo(() => entity.editorData?.symbol || '', [entity.editorData]);
  const currency = useMemo(() => entity.quoteAsset[symbol] || '', [symbol, entity.quoteAsset]);
  const scale = useMemo(() => (symbol && entity.symbolPrecision[symbol]) || 1, [
    entity.symbolPrecision,
    symbol,
  ]);

  const [inputValue, setInputValue] = useState(value);

  const onInputChange = (e) => setInputValue(e.target.value);

  const onValueChange = (value) => {
    if (!props.handler) return;

    props.handler(value);
  };

  const delayedOnValueChange = useCallback(
    debounce(() => onValueChange(inputValue), 500),
    [inputValue]
  );

  useEffect(() => {
    delayedOnValueChange();
    return delayedOnValueChange.cancel;
  }, [delayedOnValueChange, value]);

  useEffect(() => {
    setIsShow(false);
    entity.settings.forEach((el) => {
      if (el.fieldName === props.name) {
        setIsShow(true);
      }
    });
  }, [entity.settings, props.name]);

  if (!isEditing || isFiltering)
    return (
      <EntityFieldNumber {...props} isEditing={isEditing} isFiltering={isFiltering} value={value} />
    );

  const placeholder = useMemo(() => {
    return `0.${[...new Array((scale || 0) + 1)].map(() => '0').join('')}`;
  }, [scale]);

  if (!isShow) return null;

  return (
    <TextField
      type="text"
      value={(inputValue && inputValue.toString()) || ''}
      variant="outlined"
      placeholder={placeholder}
      disabled={!symbol}
      onChange={onInputChange}
      label={!symbol ? props.label : `${props.label}, ${currency}`}
      inputProps={{ scale, ...INPUT_PROPS }}
      helperText={!symbol && 'Select stock and symbol first'}
      InputProps={{
        inputComponent: COMPONENT as any,
      }}
      error={!!props.error}
    />
  );
});

export { ExchangeAmountInput };
