import React, { createElement, FC, useCallback, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { observer } from 'mobx-react';
import { ExchangeItemViewerInfo } from '~/config/pages/exchange/view/ExchangeItemViewerInfo';
import { ExchangeItemViewerReports } from '~/config/pages/exchange/view/ExchangeItemViewerReports';
import { IExchangeItem } from '~/config/pages/exchange/types';
import { ExchangeEntity } from '~/config/pages/exchange/components/ExchangeEntity';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { ExchangeItemViewerActiveOrders } from '~/config/pages/exchange/view/ExchangeItemActiveOrders';
import { ExchangeItemViewerHistory } from '~/config/pages/exchange/view/ExchangeItemHistory';
import qs from 'qs';

interface IProps {
  id: string;
  entity: ExchangeEntity;
  data: IExchangeItem;
  isLoading: boolean;
}

const TABS = {
  reports: ExchangeItemViewerReports,
  orders: ExchangeItemViewerActiveOrders,
  history: ExchangeItemViewerHistory,
};

const getTabFromUrl = () => {
  const q = qs.parse(window.location.search) as { tab: string };
  return q.tab && Object.prototype.hasOwnProperty.call(TABS, q.tab)
    ? Object.keys(TABS).indexOf(q.tab)
    : 0;
};

const ExchangeItemViewer: FC<IProps> = observer(({ id, data, isLoading, entity }) => {
  const [tab, setTab] = useState(getTabFromUrl());

  const onTabChange = useCallback(
    (_, val) => {
      setTab(val);
      history.replaceState(
        null,
        '',
        `${window.location.origin}${window.location.pathname}?tab=${Object.keys(TABS)[val]}`
      );
    },
    [setTab]
  );

  const current = Object.keys(TABS)[tab] as keyof typeof TABS;
  const currentTab = TABS[current] as any;

  useEffect(() => {
    entity.setEditorData({});
    entity.setCustomerIdOptions({});
    entity.getItem(id);
  }, [entity, id]);

  return (
    <div className={styles.wrap}>
      <ExchangeItemViewerInfo data={data} isLoading={isLoading} />

      <br />

      <Tabs value={tab} onChange={onTabChange}>
        <Tab label="Reports" />
        <Tab label="Active orders" />
        <Tab label="History" />
      </Tabs>

      <br />

      {createElement(currentTab, { entity, id })}
    </div>
  );
});

export { ExchangeItemViewer };
