import { IOrderBalance } from '~/config/pages/exchange/types';
import React, { FC } from 'react';

interface IProps {
  orderBalance: IOrderBalance | null;
}

const ExchangeOrderBalance: FC<IProps> = ({ orderBalance }) => {
  if (orderBalance == null) {
    return <div />;
  }
  return (
    <div>
      {orderBalance.freeFunds} / {orderBalance.totalFunds}
    </div>
  );
};

export { ExchangeOrderBalance };
