import { IEntityField } from 'icerockdev-admin-toolkit';
import { StockExchangeDeleteButton } from '~/config/pages/stockExchange/components/StockExchangeDeleteButton';

export const STOCK_EXCHANGE_FIELDS: IEntityField[] = [
  {
    type: 'number',
    name: 'id',
    label: 'ID',
    hideInView: true,
    hideInEdit: true,
    hideInCreate: true,
    sortable: true,
  },
  {
    type: 'string',
    name: 'name',
    label: 'name',
    hideInView: true,
    hideInEdit: true,
    hideInCreate: true,
  },

  // Editor
  {
    type: 'referenceSelect',
    name: 'stockExchangeId',
    label: 'Stock exchange',
    hideInList: true,
    hideInEdit: true,
    required: true,
  },
  {
    type: 'string',
    name: 'apiKey',
    label: 'API Key',
    hideInView: true,
    hideInList: true,
    required: true,
  },
  {
    type: 'string',
    name: 'apiSecret',
    label: 'API Secret',
    hideInView: true,
    hideInList: true,
    required: true,
  },

  // Delete button
  {
    type: 'custom',
    name: 'delete',
    label: 'Delete',
    hideInView: true,
    hideInEdit: true,
    hideInCreate: true,
    component: StockExchangeDeleteButton,
  },
];
